import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternating from '../../components/columnAlternating'
import Cta from '../../components/cta'
import ColumnAlternatingButton from '../../components/columnAlternatingButton'
import ColumnAlternatingWistia from '../../components/columnAlternatingWistia'
import Card from '../../components/card'
import Img from 'gatsby-image'
import CardIcons from '../../components/cardIcons.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import Showdown from 'showdown'

import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

export default ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section.map((sections, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12" className="pr-5 mb-5">
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                      {sections.title}
                    </h2>
                    <div
                      className="mt-3 font-w-400 text-medium"
                      dangerouslySetInnerHTML={{ __html: sections.description }}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className="mb-5">
                  <MDBCol md="3">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-blue-dark title-xs-medium title-medium">
                      Features
                    </h3>
                  </MDBCol>

                  <MDBCol md="9">
                    <MDBRow>
                      {sections.feature.map((features, index) => (
                        <MDBCol md="4" className="pb-4 item-prods" key={index}>
                          <div className="feature-icon">
                            <FontAwesomeIcon
                              icon={['fat', features.icon]}
                              size="3x"
                            />
                          </div>
                          <p className="text-medium text-blue-dark font-w-700">
                            {features.title}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="3">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-blue-dark title-xs-medium title-medium">
                      Benefits
                    </h3>
                  </MDBCol>

                  <MDBCol md="9">
                    <MDBRow>
                      {sections.benefit.map((benefits, index) => (
                        <MDBCol md="4" className="pb-4 item-prods" key={index}>
                          <div className="feature-icon">
                            <FontAwesomeIcon
                              icon={['fat', benefits.icon]}
                              size="3x"
                            />
                          </div>
                          <p className="text-medium text-blue-dark font-w-700">
                            {benefits.title}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ))}

        {/* <section className="bg-white-grey-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              {post.frontmatter.podcast.link.indexOf("wistia") > 0 ? 
                <ColumnAlternatingWistia
                  textCol={'5'}
                  imageCol={'7'}
                  placement={post.frontmatter.podcast.placement}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  height={post.frontmatter.podcast.height}
                  ctalink={post.frontmatter.podcast.ctalink}
                  ctatext={post.frontmatter.podcast.ctatext}
                  icon={post.frontmatter.podcast.icon}
                />
              :
                <ColumnAlternatingButton
                  textCol={'5'}
                  imageCol={'7'}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  imageUrl={post.frontmatter.podcast.image.childImageSharp.fluid}
                  imageAltText={post.frontmatter.podcast.alttext}
                  imageClass="rounded drop-shadow"
                  placement={post.frontmatter.podcast.placement}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  linktext={post.frontmatter.podcast.linktext}
                />
              }
            </MDBContainer>
          </MDBAnimation>
        </section> */}

        {/* {post.frontmatter.section ? (
          <section className="bg-white">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )} */}

        {/* {post.frontmatter.requirement ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="about-overview">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">{post.frontmatter.requirement.title}</h2>
                  <p className="pb-5 font-w-400 text-medium">{post.frontmatter.requirement.description}</p>

                  {post.frontmatter.requirement.section && (
                    <>
                      {post.frontmatter.requirement.section.map(
                        (sections, index) => {
                          return (
                            <ColumnAlternating
                              key={index}
                              textCol={'7'}
                              imageCol={'5'}
                              title={sections.title}
                              subtitle={sections.subtitle}
                              subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                              description={sections.description}
                              imageUrl={sections.image.childImageSharp.fluid}
                              imageAltText={sections.alttext}
                              placement={sections.placement}
                              titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                              link={sections.btnlink}
                              colour={sections.btncolour}
                            />
                          )
                        }
                      )}
                    </>
                  )}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )} */}

        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.whyworkuei.title}
              </h3>
              {post.frontmatter.whyworkuei.whywork && (
                <MDBRow>
                  <>
                    {post.frontmatter.whyworkuei.whywork.map(workwith => {
                      return (
                        <CardIcons
                          collg="4"
                          colmd="6"
                          title={workwith.title}
                          imageUrl={workwith.image.childImageSharp.fixed}
                          imageAltText={workwith.alttext}
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.insight && (
      <>
        {post.frontmatter.insight.map((insights, index) => (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                  
                    <span id={insights.anchorlink} className="anchor">
                      &nbsp;
                    </span> 
                      <div
                        className="mt-3 richtext richtext-image divlink"
                        key={index}
                        dangerouslySetInnerHTML={{ __html: converter.makeHtml(insights.description), }}
                      />                  
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
          ))}
          </>
      )}
        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.simplify.title}
                ctadescription={post.frontmatter.simplify.description}
                ctalink={post.frontmatter.simplify.link}
                ctalinktext={post.frontmatter.simplify.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => {
                          return (
                            <Fragment key={index}>
                              <Card
                                collg={(post.frontmatter.helpfulresources.helpfulres.length % 3 === 0) ? "4" : (post.frontmatter.helpfulresources.helpfulres.length % 4 === 0) ? "3" : "4" }
                                colmd="6"
                                height="9.5rem"
                                title={helpfulres.title}
                                subtitle={helpfulres.subtitle}
                                description={helpfulres.description}
                                imageUrl={helpfulres.image.childImageSharp.fluid}
                                imageAltText={helpfulres.alttext}
                                placement={helpfulres.placement}
                                link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                                titleclass="title-small"
                                descriptionClass="text-card-small"
                              />
                            </Fragment>
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "data-center" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        openingpara
        requirement {
          title
          description
          section {
            title          
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            placement
            alttext
            btnlink
            btncolour
        }
      }
        podcast {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section {
          title
          description
          feature {
            title
            icon
          }
          benefit {
            title
            icon
          }
        }  
        whyworkuei {
          title
          whywork {
            title
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        insight {
          anchorlink
          description
        }
        simplify {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 615, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`